import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Image,
  Box,
  Button,
  Flex,
  Text,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import {
  BrandTitle,
  FooterLogo,
  LoadingSpinner,
  PageHead,
  ScreenContainer,
} from "src/components";
import { UserContext } from "src/context/UserContext";
import { browserName, isIE, isSamsungBrowser } from "react-device-detect";

// Sanity
import { groq } from "next-sanity";
import client from "lib/client";

// Sanity fetch query
const query = groq`
*[_type =='landingPage'
 && _lang == $lang || _type == 'portalSettings' && _lang == $lang]{
    titlePartOne,
    titlePartTwo,
    pageTitle,
    loginButton,
    signupButton,
    availableLanguage,
    aboutLinkText,
    aboutLinkURL,
    "image": mainImage.asset->url,
    "caption": mainImage.captionImage,
    "logo": uploadLogo.asset->url,
    "logoCaption": uploadLogo.caption
}
`;

export default function Home() {
  const [data, setData] = useState([]);
  const { language, storeLanguage, languages } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    client
      .fetch(query, { lang: language })
      .then((res) => setData(res))
      .catch(console.error);
  }, [language, query]);

  // removing null properties
  data.forEach((obj) => {
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
  });

  // merge objects from sanity, destructure values
  const {
    aboutLinkText,
    aboutLinkURL,
    availableLanguage,
    caption,
    image,
    loginButton,
    logo,
    logoCaption,
    pageTitle,
    signupButton,
    titlePartOne,
    titlePartTwo,
  } = Object.assign({}, ...data);

  const navigate = (path) => {
    if (path) {
      // first parameter: pathname, second: as/alias, third: language
      router.push(`/auth/${path}`, `/auth/${path}`, { locale: language });
    }
  };

  if (!data.length) {
    return <LoadingSpinner />;
  }

  return (
    <ScreenContainer>
      <PageHead title={`${titlePartOne} ${titlePartTwo} :: ${pageTitle}`} />
      <BrandTitle titlePartOne={titlePartOne} titlePartTwo={titlePartTwo} />
      <Image src={image} alt={caption ? caption : "Real winner image"} />
      {browserName == "Facebook" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              Sidan bör öppnas i en annan webbläsare
            </AlertTitle>
            <AlertDescription display="block">
              Du har öppnat Ren vinnare i Facebooks webbläsare, vilket inte är
              optimalt. För att kunna registrera dina framsteg i utbildningen,
              välj en annan webbläsare.
              <br />
              På iOS kan du klicka på... uppe eller nere till höger och välja
              &quot;Öppna i webbläsare&quot;.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {browserName == "Instagram" ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              Sidan bör öppnas i en annan webbläsare
            </AlertTitle>
            <AlertDescription display="block">
              <Text>
                Du har öppnat Ren vinnare i Instagrams webbläsare, vilket inte
                är optimalt. För att kunna registrera dina framsteg i
                utbildningen, välj en annan webbläsare.
                <br />
                På iOS kan du klicka på... uppe eller nere till höger och välja
                &quot;Öppna i webbläsare&quot;.
              </Text>
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isSamsungBrowser ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              Sidan bör öppnas i en annan webbläsare
            </AlertTitle>
            <AlertDescription display="block">
              För bästa upplevelse rekommenderar vi att du använder en
              uppdaterad webbläsare. På Android rekommenderar vi att du använder
              Google Chrome.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      {isIE ? (
        <Alert status="info" bg={"#E42217"} color="white" mt={"1rem"}>
          <AlertIcon color="white" />
          <Box flex="1">
            <AlertTitle mr={2}>
              Sidan bör öppnas i en annan webbläsare
            </AlertTitle>
            <AlertDescription display="block">
              För bästa upplevelse rekommenderar vi att du använder en
              uppdaterad webbläsare. På Android rekommenderar vi att du använder
              Edge, Chrome eller Firefox.
            </AlertDescription>
          </Box>
        </Alert>
      ) : null}
      <Flex pt={6} justify="space-between" align="center">
        <Button mr={2} variant="standard" onClick={() => navigate("login")}>
          {loginButton}
        </Button>
        <Button ml={2} variant="standard" onClick={() => navigate("signup")}>
          {signupButton}
        </Button>
      </Flex>
      <HStack
        mt={6}
        spacing="4px"
        align="center"
        justify="center"
        textTransform="uppercase"
      >
        {availableLanguage && (
          <>
            <Button
              bg="transparent"
              padding="3px 0 0"
              fontWeight="normal"
              fontSize="0.96875rem"
              textTransform="uppercase"
              color="linkHover"
              _hover={{ bg: "transparent", textDecoration: "underline" }}
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              onClick={() =>
                storeLanguage(
                  language === languages[0] ? languages[1] : languages[0]
                )
              }
            >
              {availableLanguage}
            </Button>
            <Text>|</Text>
          </>
        )}

        {aboutLinkURL && aboutLinkText && (
          <Link href={`/home${aboutLinkURL}`} locale={language}>
            {aboutLinkText}
          </Link>
        )}
      </HStack>
      <FooterLogo logo={logo} caption={logoCaption} />
    </ScreenContainer>
  );
}
